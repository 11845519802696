import apiUrl from "./apiUrl";

let env = process.env.NODE_ENV;
console.log(env, window.location.origin);
let baseUrl;
const origin = window.location.origin;
if (env === "development") {
  baseUrl = "/";
} else {
  baseUrl = apiUrl.baseUrl;
}
export default baseUrl;
