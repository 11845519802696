import http from './axios';

export const createApplicants = (params) => {
  return http.post(`v3/investor/create/applicants`, params);
};

export const getData = (applicantid) => {
  return http.get(`v3/investor/query/applicant/${applicantid}/data`);
};

export const getParallelSignature = (url) => {
  return http.post(`v3/investor/getParallelSignature`, { url });
}

export const getParallelClientId = () => {
  return http.get(`v3/investor/getParallelClientId`);
}