/**
 * axios封装
 * 请求拦截、响应拦截、错误统一处理
 */
import axios from 'axios'
import baseUrl from '../config/baseUrl'

import { checkObjectProperty } from '../utils'

// 创建axios实例
const instance = axios.create({
  // baseURL: baseUrl,
  baseURL: baseUrl,
  timeout: 300000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    // token: window.sessionStorage.getItem("elseToken") || "",
    Authorization: 'Bearer ' + window.sessionStorage.getItem('elseToken')
  }
})
// 无响应时错误提示分类
const onError = (error, response, message) => {
  let status
  if (error && response) {
    let code = response.status
    status = code
  } else if (message.includes('timeout')) {
    status = 0
  } else if (message.includes('Network Error')) {
    status = 1
  }
  switch (status) {
    case 500:
      if (response.data.code === 401) {
        // console.log(401);
        // console.log(error, response, message,'401');
        window.location.href = window.location.origin + '/login'
      }
      break
    case 404:
      break
    case 0:
      break
    case 1:
      break
    default:
  }
}

// 删除对象属性值为空的参数
const handleEmptyParams = (config) => {
  if (config.data) {
    checkObjectProperty(config.data)
  } else if (config.params) {
    checkObjectProperty(config.params)
  }
}

/**
 * 请求拦截器
 */
instance.interceptors.request.use(
  (config) => {
    handleEmptyParams(config)
    config.headers['Authorization'] = 'Bearer ' + window.sessionStorage.getItem('elseToken') || ''
    config.headers['projectid'] = window.sessionStorage.getItem('projectId') || ''
    let env = process.env.NODE_ENV
    if (env === 'development') {
      //开发环境添加api 开启跨域代理
      config.url = `api/${config.url}`
    }
    // console.log(config);
    if (config.data && config.data.file) {
      if (
        Object.prototype.toString.call(config.data.file) === "[object File]"
      ) {
        //文件类型指定type
        config.headers["Content-Type"] = "multipart/form-data";
      }
    }
    return config
  },
  (error) => {
    Promise.error(error)
  }
)

/**
 * 响应拦截器
 */
instance.interceptors.response.use(
  // 请求成功
  (res) => {
    if (res.status === 200 || res.status === 201) {
      let { data } = res.data
      if (data) {
        return Promise.resolve(data)
      } else {
        return Promise.resolve(res)
      }
    } else {
      return Promise.reject(res.data)
    }
  },
  // 请求失败
  (error) => {
    const { response, message } = error
    onError(error, response, message)
    return Promise.reject(response || error)
  }
)

export default instance
