import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import { loadParallel } from "@parallelmarkets/vanilla";
import { ParallelProvider } from "@parallelmarkets/react";


import { getParallelClientId } from "./api/kyc";


getParallelClientId().then((res) => {
  console.log("res", res);
  const parallel = loadParallel({
    client_id: res,
    environment: "demo",
    scopes: [
      "profile",
      "identity",
      "accreditation_status",
    ]
  })

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <ParallelProvider parallel={parallel} >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ParallelProvider>
    </React.StrictMode>
  );
}).catch((err) => {

  const parallel = loadParallel({
    client_id: '',
    environment: "demo",
    scopes: [
      "profile",
      "identity",
      "accreditation_status",
    ]
  })

  const root = ReactDOM.createRoot(document.getElementById("root"));
  root.render(
    <React.StrictMode>
      <ParallelProvider parallel={parallel} >
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ParallelProvider>
    </React.StrictMode>
  );

})


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
