import React, { lazy, Suspense } from 'react'
// import BlankPage from "../pages/blank";
import Home from '../assets/layout/home.png'
import MyPortfolio from '../assets/layout/myportfolio.png'
import IssuerBoard from '../assets/layout/issuerboard.png'
import Marketplace from '../assets/layout/marketplace.png'
import HomeActive from '../assets/layout/home-active.png'
import MyPortfolioActive from '../assets/layout/myportfolio-active.png'
import IssuerBoardActive from '../assets/layout/issuerboard-active.png'
import MarketplaceActive from '../assets/layout/marketplace-active.png'

import projectListIcon from '../assets/icon/projectList.svg'
import sharesTypeIcon from '../assets/icon/sharesType.svg'
import homeIcon from '../assets/icon/home.svg'
import capTableIcon from '../assets/icon/capTable.svg'


// 懒加载、防闪屏
const lazyLoad = (src) => (
  <Suspense fallback={<>...</>}>{React.createElement(lazy(src))}</Suspense>
)
//路由配置
const routes = [
  {
    path: '/',
    element: lazyLoad(() => import('../layout/index')),
    children: [
      {
        index: true,
        element: lazyLoad(() => import('../pages/home')),
        name: 'Home',
        path: '/',
        icon: homeIcon,
        activeIcon: homeIcon
      },
      {
        element: lazyLoad(() => import('../pages/issuerHome')),
        name: 'Home',
        path: '/issuer-home/:key',
        role: 'issuer'
      },
      {
        path: '/issuer-cre-marketplace',
        element: lazyLoad(() => import('../pages/issuerCreMarketplace')),
        name: 'Marketplace',
        role: 'issuer'
      },
      {
        path: '/new-project/:id',
        element: lazyLoad(() => import('../pages/newProject')),
        name: 'New Project',
        role: 'issuer',
        hidden: true
      },
      {
        element: lazyLoad(() =>
          import('../pages/issuerHome/completionOfTransaction')
        ),
        name: 'Completion of Transaction',
        path: '/completion-of-transaction',
        role: 'issuer'
      },

      {
        path: '/cre-marketplace',
        element: lazyLoad(() => import('../pages/creMarketplace')),
        name: 'Marketplace',
        icon: projectListIcon,
        activeIcon: projectListIcon
      },

      {
        path: '/my-portfolio/:key',
        element: lazyLoad(() => import('../pages/myPortfolio')),
        name: 'My Portfolio',
        icon: sharesTypeIcon,
        activeIcon: sharesTypeIcon
      },
      {
        path: '/issuer-board',
        element: lazyLoad(() => import('../pages/issuerBoard')),
        name: 'Issuer Board',
        icon: capTableIcon,
        activeIcon: capTableIcon
      },
      {
        path: '/my-deals',
        element: lazyLoad(() => import('../pages/myDeals')),
        name: 'My Deals',
        role: 'issuer'
      },
      {
        path: '/investor-board',
        element: lazyLoad(() => import('../pages/investorBoard')),
        name: 'Investor Board',
        role: 'issuer'
      },
      {
        path: '/my-portfolio/distribution-details/:email',
        element: lazyLoad(() => import('../pages/myPortfolio/distributionDetails')),
        name: 'Distribution Details',
        hidden: true
      },      
      // {
      //   path: '/token-studio',
      //   element: lazyLoad(() => import('../pages/tokenStudio')),
      //   name: 'Token Studio'
      // },
      {
        path: '/trading',
        element: lazyLoad(() => import('../pages/trading')),
        name: 'Trading',
        role: 'issuer'
      },
      // {
      //   path: '/reports',
      //   element: lazyLoad(() => import('../pages/reports')),
      //   name: 'Reports'
      // },
      //  issuer
      {
        element: lazyLoad(() => import('../pages/subscriptionInformation')),
        name: 'Subscription Information',
        path: 'subscription-information/:id/:status',
        hidden: true
      },
      {
        element: lazyLoad(() => import('../pages/totalInvestors')),
        name: 'Total Investors',
        path: 'total-investors',
        hidden: true
      },
      {
        element: lazyLoad(() => import('../pages/totalInvestorsDetails')),
        name: 'totalInvestorsDetails',
        path: 'total-investors-details/:id',
        hidden: true
      },
      {
        element: lazyLoad(() => import('../pages/toBeConfirmedDetails')),
        name: 'toBeConfirmedDetails',
        path: 'to-be-confirmed-details/:id/:status',
        hidden: true
      },

      {
        path: '/select-project',
        element: lazyLoad(() => import('../pages/selectProject')),
        name: 'Select Project',
        hidden: true
      },
      {
        path: '/issuer/my-profile',
        element: lazyLoad(() => import('../pages/issuerMyProfile')),
        name: 'Issuer Profile',
        role: 'issuer',
        hidden: true
      }
    ]
  },
  {
    path: '/',
    element: lazyLoad(() => import('../layout/layout-no-side-bar')),
    children: [
      {
        path: '/wallet',
        element: lazyLoad(() => import('../pages/wallet')),
        name: 'Wallet'
      },
      {
        path: '/purchase-details/:id',
        element: lazyLoad(() => import('../pages/purchaseDetails')),
        name: 'Purchase Details'
      },
      {
        path: '/cre-marketplace/edit/:id',
        element: lazyLoad(() => import('../pages/creMarketplaceEdit')),
        name: 'Marketplace edit',
        hidden: true
      },
      {
        path: '/cre-marketplace/details/:id',
        element: lazyLoad(() => import('../pages/creMarketplaceDetails')),
        name: 'Marketplace details',
        hidden: true
      },
      {
        path: '/show-all/:id',
        element: lazyLoad(() => import('../pages/showAllImg')),
        name: 'Show All',
        hidden: true
      },
      {
        path: '/cre-marketplace/buy/:id',
        element: lazyLoad(() => import('../pages/buyDeel')),
        name: 'Marketplace Buy',
        hidden: true
      },
      {
        path: '/issuer-board/details/:id',
        element: lazyLoad(() => import('../pages/issuerBoard/detail')),
        name: 'Issuer Board Detail',
        role: 'investor',
        hidden: true
      },
      {
        index: true,
        element: lazyLoad(() => import('../pages/home/myProfile')),
        name: 'MyProfile',
        path: '/my-profile',
        hidden: true
      },
      {
        path: '/notifications',
        element: lazyLoad(() => import('../pages/messagePage')),
        name: 'Notifications',
        hidden: true
      },
      {
        path: '/my-portfolio/transaction-deatils',
        element: lazyLoad(() => import('../pages/transactionDeatils')),
        name: 'TransactionDeatils',
        hidden: true
      },
    ]
  },

  {
    path: '/login',
    element: lazyLoad(() => import('../pages/auth/login/index'))
  },
  {
    path: '/landing',
    element: lazyLoad(() => import('../pages/auth/landing/index'))
  },
  {
    path: '/kyc',
    element: lazyLoad(() => import('../pages/auth/kyc/index'))
  },
  {
    path: '/forget-password',
    element: lazyLoad(() => import('../pages/auth/forgetPassword/index'))
  },
  {
    path: '/reset-password',
    element: lazyLoad(() => import('../pages/auth/resetPassword/index'))
  },
  {
    path: '/sign-up',
    element: lazyLoad(() => import('../pages/auth/signUp/index'))
  },
  {
    path: '/sign-up-investor',
    element: lazyLoad(() => import('../pages/auth/signUpInvestor/index'))
  },
  {
    path: '/sign-up-success',
    element: lazyLoad(() => import('../pages/auth/signUpSuccess/index'))
  }
]
export default routes
