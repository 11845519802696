
let baseUrl;
const origin = window.location.origin;
if (origin.includes("-dev.")) {
  baseUrl = "https://api-dev.myelseware.io/";
} else {
  baseUrl = "https://api-latest.myelseware.io/";
}

const iconUrl = baseUrl + "v3/brokerdealer/icon";
const logoUrl = baseUrl + "v3/brokerdealer/logo";

export default {
    baseUrl,
    iconUrl,
    logoUrl
}


