/* 也可使用lodash提供的工具类函数 */
export const deepCopy = (data) => JSON.parse(JSON.stringify(data))

export const isArray = (target) => {
  return toString.call(target) === '[object Array]'
}

export const isObject = (target) => {
  return toString.call(target) === '[object Object]'
}

export const isString = (target) => {
  return toString.call(target) === '[object String]'
}

/**
 * @desc 去除对象中值为空的属性，包括null、undefined、全空格字符串、空对象、空数组等
 * @param {object} obj 请求参数
 */
export const checkObjectProperty = (obj) => {
  let res = {}
  Object.keys(obj).forEach((item) => {
    const value = obj[item]
    if (typeof value === 'number' || typeof value === 'boolean') {
      res[item] = value
    } else if (isObject(value) && Object.keys(value).length) {
      res[item] = value
    } else if (isArray(value) && value.length) {
      res[item] = value
    } else if (isString(value) && value.trim().length) {
      res[item] = value
    }
  })
  return res
}

/**
 * 将传入的字符串中间部分替换为 "*"，以达到隐藏信息的目的
 * @param { string } originString 需要处理的字符串
 * @param { number } startRetain 开头保留不更改的字符数量
 * @param { number } endRetain 结尾保留不更改的字符数量
 * @param { string } subString 代替字符，用于替换需要被隐藏的字符
 * @return { string } 返回处理好的字符串，如果传入值不符合要求或者其他问题，则返回空字符串
 */
 export function replaceToHidden (originString, startRetain = 1, endRetain = 1, subString = '*') {
  // 类型检测
  if (
    !originString ||
    typeof originString !== 'string' ||
    typeof startRetain !== 'number' ||
    typeof endRetain !== 'number' ||
    typeof subString !== 'string'
  ) {
    console.error('请检查传入的参数是否正确 !')
    return ''
  }
  // 动态生成正则表达式
  const reg = new RegExp(`(?<=.{${startRetain}}).{1}(?=.{${endRetain}})`, 'g')
  // 返回替换结果
  return originString.replace(reg, subString)
}